import { Component, ChangeDetectionStrategy } from '@angular/core';
import { createServiceUnavailableStatusState } from '../../../meta/utils/create-state';
import linkImgSrc from './images/link-error.svg';

@Component({
  selector: 'app-link-error',
  templateUrl: 'link-error.html',
  styleUrls: ['link-error.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkErrorComponent {
  serviceUnavailableErrorState = createServiceUnavailableStatusState();
  primaryButton = {
    link: '/student/profile',
    description: 'Вернуться на главную'
  }
  linkImg = linkImgSrc;

}
