import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Store } from 'core/store';
import { updateComponentCssVars } from 'styles/theme/theme.helpers';
import { DecorateUntilDestroy } from 'core/rxjs';
import { DIALOG_CSS_VARS_PREFIX, DIALOG_CUSTOMISABLE_PARAMS } from '../../lecta-modal-dialog.const';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-dialog-view',
  templateUrl: './lecta-dialog-view.component.html',
  styleUrls: ['./lecta-dialog-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DecorateUntilDestroy()
export class LectaDialogViewComponent implements OnInit {
  @Input() width: string;
  @Input() height: string;
  @Input() isCustomBody: boolean;
  @Input() isMobile: boolean;
  @Input() closeable = true;
  @Input() closeableByButtonOnly = true;
  @Input() closeButtonDisabled = false;
  @Input() overflowYInitial = false;
  @Input() mobileBorder: string;

  @Output() closeClick = new EventEmitter<void>();

  @ViewChild('root', { static: true }) rootRef: ElementRef<HTMLElement>;
  @ViewChild('innerBody', { static: true }) innerBodyRef: ElementRef<HTMLElement>;
  @ViewChild('body', { static: true }) bodyRef: ElementRef<HTMLElement>;

  private store = new Store({
    overflow: true,
  });

  overflow$ = this.store.select('overflow');

  constructor(private renderer2: Renderer2) {}

  ngOnInit(): void {
    updateComponentCssVars({
      renderer: this.renderer2,
      rootElement: this.rootRef,
      componentPrefix: DIALOG_CSS_VARS_PREFIX,
      params: DIALOG_CUSTOMISABLE_PARAMS,
      type: 'default',
    });
  }
}
