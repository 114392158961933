import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { createForbiddenStatusState } from '../../../meta/utils/create-state';
import imageSrc from './images/mesh.svg';
import imageMobileSrc from './images/mash-mobile.svg';
import {UserRegistryService} from "../../../user/core/service/user-registry";
import {LectaDeviceService} from "@lecta/core/device";

@Component({
  selector: 'app-mesh-came-back',
  templateUrl: 'mesh-came-back.html',
  styleUrls: ['mesh-came-back.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeshCameBackComponent implements OnInit {
  isMobile = LectaDeviceService.isMobile();
  errorState = createForbiddenStatusState();
  image: string = imageSrc;
  imageMobile: string = imageMobileSrc;
  title = 'Вы из Москвы?';
  description = 'Цифровые рабочие тетради «Просвещения» доступны в Библиотеке МЭШ.';
  primaryButton = {
    link: 'https://uchebnik.mos.ru/',
    description: 'Перейти в Библиотеку'
  }

  constructor(private activatedRoute: ActivatedRoute,private router: Router,   private userRegistryService: UserRegistryService) {}

  ngOnInit(): void {
    const user = this.userRegistryService.getCurrentUser()?.jwtData;

    if(!user){
      this.description = 'Для продолжения необходима авторизация.';
      this.primaryButton = {
        link: `${window.location.origin}/login`,
        description: 'Войти или зарегистрироваться'
      }
      return;
    }

    // https://github.com/angular/angular/issues/17004#issuecomment-414459149
    const params = this.activatedRoute.snapshot.queryParams;
    if(params?.mo){
      this.title = 'Вы из Московской области?';
      this.description = 'Цифровые рабочие тетради «Просвещения» доступны в Библиотеке ФГИС "Моя школа".';
    }else{
        this.router.navigate(['/logout'],{
          queryParams: {
            returnUrl: '/mesh-came-back'
          }
        });
    }




  }
}
