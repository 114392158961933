import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Store } from 'core/store';
import { Observable, timer } from 'rxjs';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { updateComponentCssVars } from 'styles/theme/theme.helpers';
import {
  TOOLTIP_CSS_VARS_PREFIX,
  TOOLTIP_CUSTOMISABLE_PARAMS,
  TOOLTIP_INITIAL_STATE,
  XS_BUTTON_HEIGHT_PX,
} from '../../lecta-tooltip.const';
import { TooltipOptions, TooltipPosition, TooltipStoreEvents, VisibilityState } from '../../lecta-tooltip.interface';
import { lectaTooltipVisibilityAnimation } from 'lecta-ui/tooltip/components/tooltip/lecta-tooltip-animation';
import arrowIconSrc from './images/arrow.svg?raw';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lecta-tooltip',
  templateUrl: './lecta-tooltip.component.html',
  styleUrls: ['./lecta-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [lectaTooltipVisibilityAnimation],
})
@DecorateUntilDestroy()
export class LectaTooltipComponent implements AfterViewInit {
  @ViewChild('root', { static: true }) rootRef: ElementRef<HTMLElement> | null;
  @ViewChild('body') bodyRef: ElementRef<HTMLElement>;

  @HostBinding('class.-ignore-pointer-events') ignorePointerEvents: boolean | undefined = false;

  private store = new Store<typeof TOOLTIP_INITIAL_STATE, TooltipStoreEvents>(TOOLTIP_INITIAL_STATE);
  private type: string;

  options: TooltipOptions = {};
  hidden$: Observable<void> = this.store.on('componentHidden');
  visibilityValue$ = this.store.select('visibilityValue');
  preventHiding$ = this.store.select('preventHiding');
  bodyDirection: 'vertical' | 'horizontal';
  position: TooltipPosition;
  arrow = arrowIconSrc;

  constructor(private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    // wait one loop to render child content under *ngIf
    timer(0)
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        const height = this.bodyRef.nativeElement.offsetHeight;
        this.bodyDirection = height > XS_BUTTON_HEIGHT_PX ? 'vertical' : 'horizontal';
        this.changeDetectorRef.detectChanges();
      });
  }

  show(): void {
    this.setVisibility('visible');
  }

  hide(): void {
    this.setVisibility('hidden');
  }

  onMouseEnter(): void {
    if (this.options.disabled || !this.options.interactive) {
      return;
    }

    this.setPreventHiding(true);
  }

  onMouseLeave(): void {
    if (this.options.disabled) {
      return;
    }

    this.setPreventHiding(false);
  }

  onVisibilityAnimationDone(): void {
    if (this.store.get('visibilityValue') === 'hidden') {
      this.store.fire('componentHidden');
    }
  }

  setPreventHiding(preventHiding: boolean): void {
    this.store.updateFields({ preventHiding });
  }

  setOptions(options: TooltipOptions): void {
    this.options = options;

    if (this.options.type && this.options.type !== this.type) {
      this.ignorePointerEvents = this.options.ignorePointerEvents;
      this.type = this.options.type;
      updateComponentCssVars({
        renderer: this.renderer,
        rootElement: this.rootRef,
        componentPrefix: TOOLTIP_CSS_VARS_PREFIX,
        params: TOOLTIP_CUSTOMISABLE_PARAMS,
        type: this.type,
      });
    }

    this.changeDetectorRef.markForCheck();
  }

  setPosition(position: TooltipPosition): void {
    if (position === this.position) {
      return;
    }

    this.position = position;

    this.changeDetectorRef.markForCheck();
  }

  private setVisibility(value: VisibilityState): void {
    if (!(value === 'hidden' && this.store.get('preventHiding'))) {
      this.store.updateField('visibilityValue', value);
    }
  }
}
