import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routing/routes';
import { Page403Component } from './component/page-403/page-403';
import { Page404Component } from './component/page-404/page-404';
import { Page503Component } from './component/page-503/page-503';
import { AppLayoutModule } from 'layout/module';
import { NetworkErrorPageComponent } from './component/network-error-page/network-error-page';
import { UnknownServerErrorPageComponent } from './component/unknown-server-error-page/unknown-server-error-page';
import { AppErrorPageLayoutComponent } from './component/error-page-layout/error-page-layout';
import { UnknownErrorPageComponent } from './component/unknown-error-page/unknown-error-page';
import { LogoutComponent } from './component/logout/logout';
import { MetaModule } from 'meta/meta.module';
import { QuicklinkModule } from 'ngx-quicklink';
import { EduPreloadingStrategyService } from './service/edu-preloading-strategy.service';
import { LectaSpinnerModule } from 'lecta-ui/spinner';
import { LectaButtonModule } from '@lecta/ui/button';
import { HomeworkLayoutModule } from '../lesson/homework/shared/homework-layout/homework-layout.module';
import { RelocatePluginComponent } from './component/relocate-plugin/relocate-plugin.component';
import {BillingErrorComponent} from "./component/billing-error/billing-error";
import {CommonModule} from "@angular/common";
import {MashUnreachableComponent} from "./component/mash-unreachable/mash-unreachable";
import {PageRoleInvalidComponent} from "./component/page-role-invalid/page-role-invalid";
import {MeshCameBackComponent} from "./component/mesh-came-back/mesh-came-back";
import {MOUnreachableComponent} from "./component/mo-unreachable/mo-unreachable";
import {TaskIsUnavailableComponent} from "./component/task-unavailable/task-unavailable";
import {LinkErrorComponent} from "./component/link-error/link-error";

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: EduPreloadingStrategyService,
    }),
    AppLayoutModule,
    MetaModule,
    QuicklinkModule,
    LectaSpinnerModule,
    LectaButtonModule,
    HomeworkLayoutModule,
    CommonModule,
  ],
  declarations: [
    AppErrorPageLayoutComponent,
    Page403Component,
    Page404Component,
    Page503Component,
    MeshCameBackComponent,
    PageRoleInvalidComponent,
    NetworkErrorPageComponent,
    UnknownServerErrorPageComponent,
    UnknownErrorPageComponent,
    LogoutComponent,
    RelocatePluginComponent,
    BillingErrorComponent,
    MashUnreachableComponent,
    MOUnreachableComponent,
    LinkErrorComponent,
    TaskIsUnavailableComponent
  ],
})
export class RoutingModule {}
