import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { MetaService } from 'meta/services/meta.service';
import { createNotFoundStatusState } from '../../../meta/utils/create-state';
import mashImgSrc from "./cry.png";

@Component({
  selector: 'app-mash-unreachable',
  templateUrl: 'mo-unreachable.html',
  styleUrls: ['mo-unreachable.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MOUnreachableComponent implements OnInit {
  errorState = createNotFoundStatusState();
  imgSrc = mashImgSrc;
  anotherButton = {
    link: 'mo',
    description: 'В библиотеку ФГИС «Моя школа»'
  }

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    this.metaService.setTitle('Нет лицензии');
    this.metaService.update([{ name: 'description', content: 'цифровые рабочие тетради «Просвещения» больше недоступны пользователям ФГИС «Моя школа»' }]);
  }
}
