import { TTooltipPosition } from '../interface';
import {
  OriginConnectionPosition,
  OverlayConnectionPosition,
  HorizontalConnectionPos,
  VerticalConnectionPos,
  ConnectedOverlayPositionChange,
} from '@angular/cdk/overlay';

export function getPositionFromChange(
  position: TTooltipPosition,
  positionChange: ConnectedOverlayPositionChange,
): TTooltipPosition {
  const { originX, originY } = positionChange.connectionPair;

  if (position === 'above' || position === 'below') {
    return originY === 'top' ? 'above' : 'below';
  } else {
    return originX === 'start' ? 'before' : 'after';
  }
}

export function getPositionOffset(
  position: TTooltipPosition,
  defaultOffsetPx: number,
): { offsetX: number; offsetY: number } {
  let offsetX = 0;
  let offsetY = 0;

  if (position === 'above') {
    offsetY = -defaultOffsetPx;
  } else if (position === 'below') {
    offsetY = defaultOffsetPx;
  } else if (position === 'after') {
    offsetX = defaultOffsetPx;
  } else if (position === 'before') {
    offsetX = -defaultOffsetPx;
  }

  return { offsetX, offsetY };
}

// NOTE: copy-paste from @angular/material/tooltip
export function getOriginPosition(position: TTooltipPosition): {
  main: OriginConnectionPosition;
  fallback: OriginConnectionPosition;
} {
  let originPosition: OriginConnectionPosition;

  if (position === 'above' || position === 'below') {
    originPosition = {
      originX: 'center',
      originY: position === 'above' ? 'top' : 'bottom',
    };
  } else if (position === 'before') {
    originPosition = { originX: 'start', originY: 'center' };
  } else if (position === 'after') {
    originPosition = { originX: 'end', originY: 'center' };
  } else {
    throw new Error('Tooltip: check position value');
  }

  const { x, y } = invertPosition(position, originPosition.originX, originPosition.originY);

  return {
    main: originPosition,
    fallback: { originX: x, originY: y },
  };
}

// NOTE: copy-paste from @angular/material/tooltip
export function getOverlayPosition(position: TTooltipPosition): {
  main: OverlayConnectionPosition;
  fallback: OverlayConnectionPosition;
} {
  let overlayPosition: OverlayConnectionPosition;

  if (position === 'above') {
    overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
  } else if (position === 'below') {
    overlayPosition = { overlayX: 'center', overlayY: 'top' };
  } else if (position === 'before') {
    overlayPosition = { overlayX: 'end', overlayY: 'center' };
  } else if (position === 'after') {
    overlayPosition = { overlayX: 'start', overlayY: 'center' };
  } else {
    throw new Error('Tooltip: check position value');
  }

  const { x, y } = invertPosition(position, overlayPosition.overlayX, overlayPosition.overlayY);

  return {
    main: overlayPosition,
    fallback: { overlayX: x, overlayY: y },
  };
}

// NOTE: copy-paste from @angular/material/tooltip
function invertPosition(
  position: TTooltipPosition,
  x: HorizontalConnectionPos,
  y: VerticalConnectionPos,
): { x: HorizontalConnectionPos; y: VerticalConnectionPos } {
  if (position === 'above' || position === 'below') {
    if (y === 'top') {
      y = 'bottom';
    } else if (y === 'bottom') {
      y = 'top';
    }
  } else {
    if (x === 'end') {
      x = 'start';
    } else if (x === 'start') {
      x = 'end';
    }
  }

  return { x, y };
}
