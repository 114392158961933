<lecta-dialog>
  <div lectaDialogHeader>
    <div class="title-image"></div>
    <div class="title">Не удалось отправить задание в Дневник.ру</div>
  </div>

  <ng-container lectaDialogBody>
    <div class="dialog-body">
      Ссылка на задание создана, но нам не удалось передать ее в Дневник.ру. Вы можете скопировать ссылку и отправить ее
      ученикам самостоятельно любым удобным способом.
    </div>

    <div class="homework-link-container">
      <div
        class="homework-link"
        lectaTooltip="Скопировано"
        lectaTooltipType="dark"
        [lectaTooltipDisabled]="true"
        [cdkCopyToClipboard]="getHomeworkShareUrl()"
        (cdkCopyToClipboardCopied)="onUrlCopied()"
        #copyLinkTooltip="tooltip"
        data-qa="homework-link"
      >
        {{ getHomeworkShareUrl(true) }}
      </div>
      <lecta-button
        type="primary-blue"
        size="m"
        [cdkCopyToClipboard]="getHomeworkShareUrl()"
        (cdkCopyToClipboardCopied)="onUrlCopied()"
      >
        Скопировать
      </lecta-button>

    </div>
  </ng-container>


</lecta-dialog>
