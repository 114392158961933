<div #root  [style]="options.style">


  <div
    *ngIf="visibilityValue$ | async as visibility"
    class="root -size-{{ options.size }}"
    [style.max-width]="options.maxWidth"
    [@visibility]="visibility"
    (@visibility.done)="onVisibilityAnimationDone()"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <div #body class="body -{{ bodyDirection }}">
      <ng-container *ngIf="options.contentRef">
        <div class="content">
          <ng-container *ngTemplateOutlet="options.contentRef; context: options.contentRefContext"></ng-container>
        </div>
        <div class="buttons" *ngIf="options.actionRef">
          <ng-container *ngTemplateOutlet="options.actionRef"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!options.contentRef">
        <div class="content" [innerHTML]="options.content"></div>
      </ng-container>
    </div>

    <div class="arrow -{{ position }}" >
      <lecta-icon class="arrow-icon" [svgContent]="arrow"></lecta-icon>
    </div>
  </div>
</div>
