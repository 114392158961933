<app-error-page-layout
  [appMetaTags]=""
  [appPrerenderMetaTags]="serviceUnavailableErrorState"
  [primaryActionButton]="primaryButton"
  [image]="linkImg"
>
  <ng-container title>Неверная ссылка на задание</ng-container>
  <ng-container description>
    <p class="text">Пожалуйста, обратись к учителю за правильной ссылкой.</p>
  </ng-container>
</app-error-page-layout>
