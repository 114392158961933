// NOTE: copy-paste from @angular/material/tooltip
import { OriginConnectionPosition } from '@angular/cdk/overlay';
import { TooltipPosition } from '../lecta-tooltip.interface';
import { invertPosition } from './invert-position.helper';

export function getOriginPosition(position: TooltipPosition): {
  main: OriginConnectionPosition;
  fallback: OriginConnectionPosition;
} {
  let originPosition: OriginConnectionPosition;

  if (position === 'top' || position === 'bottom') {
    originPosition = {
      originX: 'center',
      originY: position === 'top' ? 'top' : 'bottom',
    };
  } else if (position === 'left') {
    originPosition = { originX: 'start', originY: 'center' };
  } else if (position === 'right') {
    originPosition = { originX: 'end', originY: 'center' };
  } else {
    throw new Error('Tooltip: check position value');
  }

  const { x, y } = invertPosition(position, originPosition.originX, originPosition.originY);

  return {
    main: originPosition,
    fallback: { originX: x, originY: y },
  };
}
