import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

const START_OPACITY = 0;
const FINAL_OPACITY = 1;
const DURATION_MS = 200;
const ANIMATION_TYPE = 'ease';

export const lectaTooltipVisibilityAnimation: AnimationTriggerMetadata = trigger('visibility', [
  state('initial, void, hidden', style({ opacity: START_OPACITY })),
  transition('* => visible', [
    style({ opacity: 0 }),
    animate(`${DURATION_MS}ms ${ANIMATION_TYPE}`, style({ opacity: FINAL_OPACITY })),
  ]),
  transition('* => hidden', [animate(`${DURATION_MS}ms ${ANIMATION_TYPE}`, style({ opacity: START_OPACITY }))]),
]);
