import { formatDate } from '@angular/common';
import { ISODate } from 'misc';
import { StudentClass } from '../../homework/core/interface';
import { IJwtUser } from 'user/core/interface';

export function isClassesEqual(class1: StudentClass, class2: StudentClass): boolean {
  return class1.classNumber === class2.classNumber && class1.classLetter === class2.classLetter;
}

export function getClassTitle(classObject: StudentClass): string {
  if (!classObject.classNumber) {
    return '';
  }

  let symbolString = '';

  if (classObject.classLetter) {
    symbolString = ` (${classObject.classLetter
      .replace(/[()]/g, '')
      .replace(/(^[^а-яА-Яa-zA-Z0-9]+)|([^а-яА-Яa-zA-Z0-9]+$)/g, '')})`; // удаляет в начале и в конце строки все не буквенные и не числовые символы
  }

  return classObject.classNumber + symbolString;
}

export function getDefaultHomeworkTitle(createdAt: ISODate | Date): string {
  return `Задание от ${formatDate(createdAt, 'd MMMM, HH:mm', 'ru')}`;
}

export function getStudentMark(score: number): number {
  const grade = Math.floor(score / 20) + 1;
  return grade >= 5 ? 5 : grade;
}

export const getUserName = (user: IJwtUser): string => {
  if (user.name) {
    return user.name + (user.surname ? ' ' + user.surname : '');
  }

  return user.email;
};
