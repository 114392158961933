import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
  RepositionScrollStrategy,
  ScrollDispatcher,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { fromEvent, NEVER, Subject, timer } from 'rxjs';
import { delay, filter, map, share, switchMap, take } from 'rxjs/operators';
import { ITooltipComponent, TOOLTIP_COMPONENT, TTheme, TTooltipPosition } from '../interface';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { getOriginPosition, getOverlayPosition, getPositionFromChange, getPositionOffset } from './helpers';
import './ios-height-patch';

const DEFAULT_TOOLTIP_OFFSET_PX = 8;
const POSITION_VIEWPORT_MARGIN_PX = 8;
const SCROLL_THROTTLE_MS = 20;
const DEFAULT_SHOW_DELAY_MS = 200;

@DecorateUntilDestroy()
@Directive({
  selector: '[appTooltip]',
  exportAs: 'tooltip',
})
export class TooltipDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input('tooltipContent') content?: string;
  @Input('tooltipContentRef') contentRef?: TemplateRef<unknown>;
  @Input('tooltipContentRefContext') contentRefContext?: Object;
  @Input('tooltipTitle') title?: string;
  @Input('tooltipForMobile') isMobile?: boolean = false;
  @Input('tooltipPosition') position: TTooltipPosition = 'above';
  @Input('tooltipMaxWidth') maxWidth?: string;
  @Input('tooltipDisabled') disabled = false;
  @Input('tooltipShowDelay') showDelay = DEFAULT_SHOW_DELAY_MS;
  @Input('tooltipHideDelay') hideDelay?: number;
  @Input('tooltipIgnoreMouseEnter') ignoreMouseEnter = false;
  @Input('tooltipCustomTooltipComponent') customTooltipComponent?: Type<ITooltipComponent>;
  @Input('tooltipHoverElement') hoverElement?: HTMLElement;
  @Input('tooltipHideOnClick') hideOnClick = false;
  @Input('tooltipManual') manual = false;
  @Input('tooltipHideBySwipe') hideBySwipe = false;
  @Input('tooltipTheme') theme: TTheme = 'default';

  @Output('tooltipHidden') hidden = new EventEmitter<void>();

  private overlayRef: OverlayRef;
  private portal: ComponentPortal<ITooltipComponent>;
  private tooltipInstance: ITooltipComponent | null = null;
  private deferredTooltip = new Subject<boolean>();
  private showMobileTooltip = false;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
    @Inject(TOOLTIP_COMPONENT) private globalTooltipComponent: Type<ITooltipComponent>,
  ) {}

  ngOnInit(): void {
    this.initDeferredTooltipObserver();
  }

  ngAfterViewInit(): void {
    this.initHoverElementEventHandlers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.position) {
      this.updatePosition();
    }

    if (changes.disabled && this.disabled) {
      this.hide();
    }

    const hasTooltipInputChanges =
      changes.content ||
      changes.title ||
      changes.contentRef ||
      changes.contentRefContext ||
      changes.maxWidth ||
      changes.hideDelay ||
      changes.theme;

    if (!this.disabled && hasTooltipInputChanges) {
      this.updateTooltipInputs();
    }
  }

  ngOnDestroy(): void {
    this.stopDeferredShowingTooltip();

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.tooltipInstance = null;
  }

  isOpen(): boolean {
    return !!this.tooltipInstance;
  }

  show(): void {
    if (this.disabled || (!this.content && !this.title && !this.contentRef)) {
      return;
    }

    const overlayRef = this.createOverlay();
    this.detach();

    if (!this.portal) {
      const tooltipComponent = this.customTooltipComponent || this.globalTooltipComponent;
      this.portal = new ComponentPortal(tooltipComponent, this.viewContainerRef);
    }

    const attachedComponentRef = overlayRef.attach(this.portal);
    attachedComponentRef.changeDetectorRef.detectChanges();

    this.tooltipInstance = attachedComponentRef.instance;

    this.tooltipInstance.hidden$.pipe(delay(0), takeUntilDestroyed(this)).subscribe(() => {
      this.detach();
      this.hidden.next();
    });

    this.updateTooltipInputs();

    this.tooltipInstance.show(this.showDelay);
  }

  hide(): void {
    this.stopDeferredShowingTooltip();

    if (!this.tooltipInstance) {
      return;
    }

    this.tooltipInstance.setPreventHiding(false);
    this.tooltipInstance.hide(this.hideDelay);
  }

  private onMouseEnter(): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.setPreventHiding(true);

      return;
    }

    this.deferShowingTooltip();
  }

  private onMouseLeave(): void {
    this.hide();
  }

  private getHoverElement(): HTMLElement {
    return this.hoverElement || this.elementRef.nativeElement;
  }

  private initHoverElementEventHandlers(): void {
    const hoverElement = this.getHoverElement();

    if (!this.manual && !this.isMobile) {
      fromEvent(hoverElement, 'mouseenter')
        .pipe(takeUntilDestroyed(this))
        .subscribe(() => this.onMouseEnter());

      fromEvent(hoverElement, 'mouseleave')
        .pipe(takeUntilDestroyed(this))
        .subscribe(() => this.onMouseLeave());
    }

    if(this.isMobile){
      fromEvent(hoverElement, 'touchstart')
        .pipe(takeUntilDestroyed(this))
        .subscribe(() => this.mobileView());
    }

    if (this.hideOnClick) {
      fromEvent(hoverElement, 'click')
        .pipe(takeUntilDestroyed(this))
        .subscribe(() => this.hide());
    }
  }

  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const positionStrategy = this.getOverlayPositionStrategy();
    const scrollStrategy = this.getOverlayScrollStrategy();

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
    });

    this.updatePosition();

    this.handlePositionChanges(positionStrategy);

    this.overlayRef
      .detachments()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this.detach());

    return this.overlayRef;
  }

  private getOverlayPositionStrategy(): FlexibleConnectedPositionStrategy {
    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    return this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withViewportMargin(POSITION_VIEWPORT_MARGIN_PX)
      .withScrollableContainers(scrollableAncestors);
  }

  private getOverlayScrollStrategy(): RepositionScrollStrategy {
    return this.overlay.scrollStrategies.reposition({
      scrollThrottle: SCROLL_THROTTLE_MS,
    });
  }

  private updatePosition(): void {
    if (!this.overlayRef) {
      return;
    }

    const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = getOriginPosition(this.position);
    const overlay = getOverlayPosition(this.position);

    positionStrategy.withPositions([
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback },
    ]);
  }

  private handlePositionChanges(positionStrategy: FlexibleConnectedPositionStrategy): void {
    const position$ = positionStrategy.positionChanges.pipe(
      map(positionChange => getPositionFromChange(this.position, positionChange)),
      share(),
    );

    position$
      .pipe(
        filter(() => !!this.tooltipInstance),
        takeUntilDestroyed(this),
      )
      .subscribe(position => this.tooltipInstance!.setPosition(position));

    position$
      .pipe(
        map(position => getPositionOffset(position, DEFAULT_TOOLTIP_OFFSET_PX)),
        takeUntilDestroyed(this),
      )
      .subscribe(({ offsetX, offsetY }) => {
        positionStrategy.withDefaultOffsetX(offsetX);
        positionStrategy.withDefaultOffsetY(offsetY);
      });
  }

  private updateTooltipInputs(): void {
    if (!this.tooltipInstance) {
      return;
    }

    this.tooltipInstance.setOptions({
      title: this.title,
      content: this.content,
      contentRef: this.contentRef,
      contentRefContext: this.contentRefContext,
      maxWidth: this.maxWidth,
      hideDelay: this.hideDelay,
      manual: this.manual,
      ignoreMouseEnter: this.ignoreMouseEnter,
      hideBySwipe: this.hideBySwipe,
      theme: this.theme,
    });

    // wait for first painting before position update
    this.ngZone.onMicrotaskEmpty
      .asObservable()
      .pipe(
        take(1),
        filter(() => !!this.tooltipInstance && !!this.overlayRef),
        takeUntilDestroyed(this),
      )
      .subscribe(() => this.overlayRef!.updatePosition());
  }

  private detach(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    this.tooltipInstance = null;
  }

  private stopDeferredShowingTooltip(): void {
    this.deferredTooltip.next(false);
  }

  private deferShowingTooltip(): void {
    this.deferredTooltip.next(true);
  }

  private initDeferredTooltipObserver(): void {
    this.deferredTooltip
      .asObservable()
      .pipe(
        // handle asynchronously to avoid double-click issue in iOS12 (https://devjira.skyeng.ru/browse/VIM-9448)
        switchMap(show => (show ? timer(0) : NEVER)),
        takeUntilDestroyed(this),
      )
      .subscribe(() => this.show());
  }

  mobileView(): void{
    this.showMobileTooltip = !this.showMobileTooltip;
    return this.showMobileTooltip ? this.onMouseEnter() : this.hide();
  }
}
