<app-error-page-layout
  [appMetaTags]=""
  [appPrerenderMetaTags]="serviceUnavailableErrorState"
  [primaryActionButton]="anotherButton"
  [image]="imgSrc"
>
  <ng-container title>Задание недоступно</ng-container>
  <ng-container description>
    <p class="text">К сожалению, задание недоступно. Пожалуйста, выберите другое задание.</p>
  </ng-container>
</app-error-page-layout>
