import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { AuthService } from 'user/core/service/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { ProsvIdUrlBuilderService } from 'integrations/prosv/service/prosv-id-url-builder.service';
import { UserRegistryService } from 'user/core/service/user-registry';
import {LectaLocalStorageService} from "@lecta/core/local-storage";
import {ProsvUuidKey} from "../../../user/core/const";

@Component({
  template: `
    <lecta-spinner position="fixed" size="l"></lecta-spinner>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DecorateUntilDestroy()
export class LogoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private userRegistryService: UserRegistryService,
    private prosvIdUrlBuilderService: ProsvIdUrlBuilderService,
    private localStorage: LectaLocalStorageService
  ) {}

  ngOnInit(): void {
    if (this.userRegistryService.getCurrentUser() === null) {
      this.router.navigate(['/'], { replaceUrl: true });
    }

    this.authService
      .logout()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        this.localStorage.remove(ProsvUuidKey);
        const returnUrl = this.activateRoute.snapshot.queryParams.returnUrl;

        if (returnUrl) {
          this.router.navigateByUrl(returnUrl, { replaceUrl: true });
          return;
        }

        if (!this.prosvIdUrlBuilderService.isProsvIdHost(document.referrer)) {
          window.location.href = this.prosvIdUrlBuilderService.getLogoutUrl();
        }

        if(!returnUrl){
            this.navigateHome();
        }
      });
  }

  private navigateHome(): void {
    this.router.navigate(['/'], { replaceUrl: true });
  }
}
