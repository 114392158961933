import startOfWeek from 'date-fns/startOfWeek';
import { ISODate } from 'misc';
import { getDefaultHomeworkTitle } from '../core/helper';

export function getHomeworkShareUrl(hash: string, noProtocol = false): string {
  if (!hash) {
    return '';
  }

  const baseURI = `${window.location.origin}/`;

  const url = `${baseURI}student/${hash}`;

  return noProtocol ? url.replace('https://', '') : url;
}

export function getReferralShareUrl(teacherHash: string, noProtocol = false): string {
  if (!teacherHash) {
    return '';
  }

  const baseURI = `${window.location.origin}/`;

  const url = `${baseURI}?ref=${teacherHash}`;

  return noProtocol ? url.replace('https://', '') : url;
}

export function getHomeworkTitle(homeworkTitle: string | null, createdAt: ISODate): string {
  return homeworkTitle || getDefaultHomeworkTitle(createdAt);
}

export function getStartOfWeek(fromCurrentWeekDiff = 0): Date {
  // @ts-ignore
  return startOfWeek(Date.now() + fromCurrentWeekDiff * 7 * 24 * 60 * 60 * 1000, { weekStartsOn: 1 });
}
