import { InjectionToken, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AppTooltipComponent } from './component/tooltip';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type TTooltipPosition = 'above' | 'below' | 'before' | 'after';
// eslint-disable-next-line @typescript-eslint/naming-convention
export type TVisibilityState = 'initial' | 'visible' | 'hidden';
// eslint-disable-next-line @typescript-eslint/naming-convention
export type TTheme = 'default' | 'dark';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITooltipOptions {
  content?: string;
  contentRef?: TemplateRef<unknown>;
  contentRefContext?: Object;
  title?: string;
  maxWidth?: string;
  hideDelay?: number;
  ignoreMouseEnter?: boolean;
  manual?: boolean;
  hideBySwipe?: boolean;
  theme?: TTheme;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITooltipComponent {
  hidden$: Observable<void>;

  show(delay?: number): void;
  hide(delay?: number): void;
  setPreventHiding(preventHiding: boolean): void;
  setOptions(options: ITooltipOptions): void;
  setPosition(position: TTooltipPosition): void;
}

export const TOOLTIP_COMPONENT = new InjectionToken<AppTooltipComponent>('app.tooltip.component');
