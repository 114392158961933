<div
  #root
  class="root"
  [class.-mobile]="isMobile"
  [class.-custom-body]="isCustomBody"
  [style.width]="width"
  [style.height]="height"
  [style.border-radius]="isMobile ? mobileBorder : '12px'"

>
  <header class="header">
    <ng-content select="[lectaDialogHeader]"></ng-content>
  </header>
  <div
    #body
    class="body"
    [class.-overflow]="overflow$ | async"
    [class.-empty]="!innerBody.children.length"
    [class.-overflow-y-init]="overflowYInitial"
  >
    <div #innerBody class="inner-body">
      <ng-content select="[lectaDialogBody]"></ng-content>
    </div>

  </div>
  <footer class="footer">
    <ng-content select="[lectaDialogFooter]"></ng-content>
  </footer>

  <button *ngIf="closeable || closeableByButtonOnly"
          class="close-button"
          [disabled]="closeButtonDisabled"
          (click)="closeClick.emit()">
    <lecta-icon  [size]="'m'" name="close"></lecta-icon>
  </button>
</div>
