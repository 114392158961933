<app-error-page-layout
  [appMetaTags]=""
  [appPrerenderMetaTags]="errorState"
  [image]="imgSrc"
  [anotherActionButton]="anotherButton">
  <ng-container title>Дорогие друзья!</ng-container>
  <ng-container description>
    <p class="text">Доступ временно закрыт. Пожалуйста, свяжитесь с технической поддержкой ФГИС «Моя школа».</p>
  </ng-container>
</app-error-page-layout>
