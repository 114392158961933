import { OverlayConnectionPosition } from '@angular/cdk/overlay';
import { TooltipPosition } from '../lecta-tooltip.interface';
import { invertPosition } from './invert-position.helper';

// NOTE: copy-paste from @angular/material/tooltip
export function getOverlayPosition(position: TooltipPosition): {
  main: OverlayConnectionPosition;
  fallback: OverlayConnectionPosition;
} {
  let overlayPosition: OverlayConnectionPosition;

  if (position === 'top') {
    overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
  } else if (position === 'bottom') {
    overlayPosition = { overlayX: 'center', overlayY: 'top' };
  } else if (position === 'left') {
    overlayPosition = { overlayX: 'end', overlayY: 'center' };
  } else if (position === 'right') {
    overlayPosition = { overlayX: 'start', overlayY: 'center' };
  } else {
    throw new Error('Tooltip: check position value');
  }

  const { x, y } = invertPosition(position, overlayPosition.overlayX, overlayPosition.overlayY);

  return {
    main: overlayPosition,
    fallback: { overlayX: x, overlayY: y },
  };
}
