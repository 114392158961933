import { Routes } from '@angular/router';
import { NetworkErrorPageComponent } from '../../component/network-error-page/network-error-page';
import { Page403Component } from '../../component/page-403/page-403';
import { Page404Component } from '../../component/page-404/page-404';
import { Page503Component } from '../../component/page-503/page-503';
import { UnknownErrorPageComponent } from '../../component/unknown-error-page/unknown-error-page';
import { UnknownServerErrorPageComponent } from '../../component/unknown-server-error-page/unknown-server-error-page';
import {BillingErrorComponent} from "../../component/billing-error/billing-error";
import {MashUnreachableComponent} from "../../component/mash-unreachable/mash-unreachable";
import {PageRoleInvalidComponent} from "../../component/page-role-invalid/page-role-invalid";
import {MeshCameBackComponent} from "../../component/mesh-came-back/mesh-came-back";
import {MOUnreachableComponent} from "../../component/mo-unreachable/mo-unreachable";
import {TaskIsUnavailableComponent} from "../../component/task-unavailable/task-unavailable";
import {LinkErrorComponent} from "../../component/link-error/link-error";


export const defaultErrorPagesRoutes: Routes = [
  // restricted
  { path: '403', component: Page403Component },
  // not found
  { path: '404', component: Page404Component },
  // network error
  { path: 'network-error', component: NetworkErrorPageComponent },
  // unknown server error
  { path: 'unknown-server-error', component: UnknownServerErrorPageComponent },
  // unknown error
  { path: 'unknown-error', component: UnknownErrorPageComponent },
  // platform error
  { path: '503', component: Page503Component },
  // role error
  {path: 'role-invalid',component: PageRoleInvalidComponent},
  // billing error
  { path: 'billing-error', component: BillingErrorComponent },
  // МЭШ ВСЁ
  { path: 'mash-unreachable', component: MashUnreachableComponent },
  // МО всё
  { path: 'mo-unreachable', component: MOUnreachableComponent },
  // МЭШ обратно
  { path: 'mesh-came-back', component: MeshCameBackComponent },
  // не корректная ссылка на задание
  { path: 'link-error', component: LinkErrorComponent },
  // не корректная ссылка на задание
  {path: 'task-is-unavailable', component: TaskIsUnavailableComponent}
];
