import { Component, ChangeDetectionStrategy } from '@angular/core';
import { createServiceUnavailableStatusState } from '../../../meta/utils/create-state';
import taskUnavailableImgSrc from './images/task-unavailable.svg';

@Component({
  selector: 'app-task-unavailable-error',
  templateUrl: 'task-unavailable.html',
  styleUrls: ['task-unavailable.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskIsUnavailableComponent  {
  serviceUnavailableErrorState = createServiceUnavailableStatusState();
  anotherButton = {
    link: '/profile',
    description: 'Вернуться на главную'
  }
  imgSrc = taskUnavailableImgSrc;
}
