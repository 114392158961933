import { VisibilityState } from 'lecta-ui/tooltip/lecta-tooltip.interface';

export const TOOLTIP_CSS_VARS_PREFIX = 'tooltip';
export const TOOLTIP_CUSTOMISABLE_PARAMS = ['backgroundColor', 'textColor', 'boxShadow'];
export const XS_BUTTON_HEIGHT_PX = 36;
export const TOOLTIP_INITIAL_STATE = {
  visibilityValue: 'initial' as VisibilityState,
  preventHiding: false,
};

export const DEFAULT_SHOW_DELAY_MS = 200;
export const DEFAULT_TOOLTIP_OFFSET_PX = 8;
export const POSITION_VIEWPORT_MARGIN_PX = 8;
export const SCROLL_THROTTLE_MS = 20;
