import { Routes } from '@angular/router';
import { EduRouteData } from 'routing/interface';
import { FullyRegisteredTeacherGuard } from 'user/core/routing/guard/fully-registered-teacher-guard';
import { NotFullyRegisteredTeacherGuard } from 'user/core/routing/guard/not-fully-registered-teacher-guard';
import { SetTeacherRoleGuard } from 'user/core/routing/guard/set-teacher-role';
import { VirtualClassStartAuthTeacherProviderGuard } from '../../../integrations/virtual-class/guards/virtual-class-start-auth-teacher-provider.guard';

export const teacherRoutes: Routes = [
  {
    canActivate: [SetTeacherRoleGuard],
    path: 'teacher/certificate',
    loadChildren: () => import('../../../teacher/+certificate/module').then(module => module.TeacherCertificateModule),
  },
  {
    canActivate: [SetTeacherRoleGuard, NotFullyRegisteredTeacherGuard],
    path: 'teacher',
    pathMatch: 'full',
    data: { seo: { allowIndex: true } } as EduRouteData,
    loadChildren: () =>
      import('../../../landing/+teacher/teacher-landing.module').then(module => module.TeacherLandingModule),
  },
  {
    canActivate: [VirtualClassStartAuthTeacherProviderGuard, SetTeacherRoleGuard, FullyRegisteredTeacherGuard],
    path: 'teacher/profile',
    loadChildren: () => import('../../../teacher/+cabinet/module').then(module => module.TeacherModule),
  },
  {
    canActivate: [SetTeacherRoleGuard],
    path: 'teacher/journal',
    loadChildren: () =>
      import('../../../teacher/+journal/teacher-journal.module').then(module => module.TeacherJournalModule),
  },
  {
    canActivate: [SetTeacherRoleGuard],
    path: 'teacher/terms-of-use',
    loadChildren: () =>
      import('../../../teacher/terms-of-use/terms-of-use.module').then(module => module.TeacherTermsOfUseModule),
  },

  {
    canActivate: [SetTeacherRoleGuard],
    path: 'teacher/instructions',
    loadChildren: () =>
      import('../../../teacher/instructions/instructions.module').then(module => module.TeacherInstructionsModule),
  },
  {
    path: 'registration',
    redirectTo: 'login',
  },
];
