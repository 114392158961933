import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { TooltipPosition } from '../lecta-tooltip.interface';

// NOTE: copy-paste from @angular/material/tooltip
export function invertPosition(
  position: TooltipPosition,
  x: HorizontalConnectionPos,
  y: VerticalConnectionPos,
): { x: HorizontalConnectionPos; y: VerticalConnectionPos } {
  if (position === 'top' || position === 'bottom') {
    if (y === 'top') {
      y = 'bottom';
    } else if (y === 'bottom') {
      y = 'top';
    }
  } else {
    if (x === 'end') {
      x = 'start';
    } else if (x === 'start') {
      x = 'end';
    }
  }

  return { x, y };
}
