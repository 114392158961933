<lecta-dialog-overlay [hasBackground]="hasBackground"  [mobilePosition]="mobilePosition" [isMobile]="isMobile" [limitDialogHeight]="limitDialogHeight" (backdropClick)="closeByOverlayClick()">
  <lecta-dialog-view
    [width]="width"
    [height]="height"
    [isCustomBody]="isCustomBody"
    [isMobile]="isMobile"
    [closeable]="closeable"
    [closeableByButtonOnly]="closeableByButtonOnly"
    [closeButtonDisabled]="closeButtonDisabled"
    [overflowYInitial]="overflowYInitial"
    (closeClick)="closeByButtonClick()"
    [mobileBorder]="mobileBorder"
  >
    <ng-container lectaDialogHeader>
      <ng-content select="[lectaDialogHeader]"></ng-content>
    </ng-container>
    <ng-container lectaDialogBody>
      <ng-content select="[lectaDialogBody]"></ng-content>
    </ng-container>
    <ng-container lectaDialogFooter>
      <ng-content select="[lectaDialogFooter]"></ng-content>
    </ng-container>
  </lecta-dialog-view>
</lecta-dialog-overlay>
