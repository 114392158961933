import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { TooltipPosition } from '../lecta-tooltip.interface';

export function getPositionFromChange(
  position: TooltipPosition,
  positionChange: ConnectedOverlayPositionChange,
): TooltipPosition {
  const { originX, originY } = positionChange.connectionPair;

  if (position === 'top' || position === 'bottom') {
    return originY === 'top' ? 'top' : 'bottom';
  } else {
    return originX === 'start' ? 'left' : 'right';
  }
}
