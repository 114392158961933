import { FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { LectaDeviceService } from '@lecta/core/device';

if (LectaDeviceService.isIos()) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle
  const getExactOverlayYOriginal = (FlexibleConnectedPositionStrategy.prototype as any)._getExactOverlayY;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, functional/immutable-data, no-underscore-dangle
  (FlexibleConnectedPositionStrategy.prototype as any)._getExactOverlayY = function (...args: any): any {
    const styles = getExactOverlayYOriginal.apply(this, args);
    if (window.innerWidth < window.innerHeight) {
      // eslint-disable-next-line functional/immutable-data
      styles.bottom = parseInt(styles.bottom) - document.documentElement.clientHeight + window.innerHeight + 'px';
    }
    return styles;
  };
}
