import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { LectaDialogInstance } from '@lecta/ui/modal-dialog';
import {Subject, timer} from "rxjs";
import {TrackingService} from "@lecta/tracking/service/tracking";
import {getHomeworkShareUrl} from "../../../../teacher/+cabinet/helpers";
import {switchMap} from "rxjs/operators";
import {DecorateUntilDestroy, takeUntilDestroyed} from "@lecta/core/rxjs";
import {TooltipDirective} from "@lecta/ui/tooltip/directive/tooltip";

const LINK_COPIED_TOOLTIP_SHOW_MS = 2 * 1000;

@Component({
  selector: 'app-dnevnikru-create-homework-error-popup',
  templateUrl: './dnevnikru-create-homework-error-popup.component.html',
  styleUrls: ['./dnevnikru-create-homework-error-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DecorateUntilDestroy()
export class DnevnikruCreateHomeworkErrorPopupComponent implements OnInit{
  private isUrlCopied = new Subject<void>();
  @ViewChild('copyLinkTooltip') copyLinkTooltipRef: TooltipDirective;
  hash: string;

  constructor(
    private trackingService: TrackingService,
    @Inject(LectaDialogInstance)
    private dialogInstance: LectaDialogInstance<null, string>,
  ) {
    this.hash = this.dialogInstance.data + '?provider=dnevnik';
  }

  ngOnInit(): void {
    this.isUrlCopied
      .pipe(
        switchMap(() => {
          this.showCopyLinkTooltip();

          return timer(LINK_COPIED_TOOLTIP_SHOW_MS);
        }),
        takeUntilDestroyed(this),
      )
      .subscribe(() => this.hideCopyLinkTooltip());
  }


  onUrlCopied(): void {
    this.trackingService.trackEvent('teacher_link_copied');

    this.isUrlCopied.next();
  }

  getHomeworkShareUrl(noProtocol = false): string {

    return getHomeworkShareUrl(this.hash, noProtocol);
  }

  close(): void {
    this.dialogInstance.close({ rejected: false });
  }

  private showCopyLinkTooltip(): void {
    if (this.copyLinkTooltipRef) {
      this.copyLinkTooltipRef.show();
    }
  }

  private hideCopyLinkTooltip(): void {
    if (this.copyLinkTooltipRef) {
      this.copyLinkTooltipRef.hide();
    }
  }

}
