import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
  RepositionScrollStrategy,
  ScrollDispatcher,
} from '@angular/cdk/overlay';
import { Store } from 'core/store';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  pluck,
  share,
  skip,
  switchMap,
  take,
} from 'rxjs/operators';
import { fromEvent, timer } from 'rxjs';
import { TooltipPosition, TooltipSize } from '../lecta-tooltip.interface';
import { LectaTooltipComponent } from '../components/tooltip/lecta-tooltip.component';
import { LectaTooltipReferenceDirective } from './lecta-tooltip-reference.directive';
import {
  DEFAULT_SHOW_DELAY_MS,
  DEFAULT_TOOLTIP_OFFSET_PX,
  POSITION_VIEWPORT_MARGIN_PX,
  SCROLL_THROTTLE_MS,
} from '../lecta-tooltip.const';
import { getOriginPosition, getOverlayPosition, getPositionFromChange, getPositionOffset } from '../helpers';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[lectaTooltip]',
  exportAs: 'tooltip',
})
@DecorateUntilDestroy()
export class LectaTooltipDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input('lectaTooltip') content?: string;
  @Input('lectaTooltipSize') size: TooltipSize = 'm';
  @Input('lectaTooltipType') type = 'default';
  @Input('lectaTooltipContentRef') contentRef?: TemplateRef<unknown>;
  @Input('lectaTooltipContentRefContext') contentRefContext?: Object;
  @Input('lectaTooltipActionRef') actionRef?: TemplateRef<unknown>;
  @Input('lectaTooltipPosition') position: TooltipPosition = 'top';
  @Input('lectaTooltipMaxWidth') maxWidth?: string;
  @Input('lectaTooltipDisabled') disabled = false;
  @Input('lectaTooltipInteractive') interactive = false;
  @Input('lectaTooltipIgnorePointerEvents') ignorePointerEvents = false;
  @Input('lectaTooltipStyle') style: string;

  @Output('lectaTooltipHidden') hidden = new EventEmitter<void>();

  @ContentChild(LectaTooltipReferenceDirective, { read: ElementRef })
  tooltipReferenceDirective: ElementRef<HTMLElement>;

  private portal: ComponentPortal<LectaTooltipComponent>;
  private overlayRef: OverlayRef;

  private tooltipInstance: LectaTooltipComponent | null = null;
  private store = new Store({
    state: '' as 'hidden' | 'shown',
    trigger: '' as 'show' | 'hide',
  });

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
  ) {}

  ngOnInit(): void {
    this.initLifecycle();
  }

  ngAfterViewInit(): void {
    this.initHoverElementEventHandlers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasTooltipInputChanges =
      changes.content ||
      changes.contentRef ||
      changes.contentRefContext ||
      changes.actionRef ||
      changes.maxWidth ||
      changes.disabled ||
      changes.style ||
      changes.interactive;

    if (hasTooltipInputChanges) {
      this.updateTooltipInputs();
    }
  }

  ngOnDestroy(): void {
    this.detach();
  }

  isOpen(): boolean {
    return this.store.get('state') === 'shown';
  }

  toggle(): void {
    if (this.isOpen()) {
      this.hide();
    } else {
      this.show();
    }
  }

  show(): void {
    if (this.tooltipInstance) {
      return;
    }

    if (!this.content && !this.contentRef) {
      return;
    }

    const overlayRef = this.createOverlay();
    this.detach();

    if (!this.portal) {
      this.portal = new ComponentPortal(LectaTooltipComponent, this.viewContainerRef);
    }
    const attachedComponentRef = overlayRef.attach(this.portal);
    attachedComponentRef.changeDetectorRef.detectChanges();
    this.tooltipInstance = attachedComponentRef.instance;

    this.tooltipInstance?.hidden$.pipe(delay(0), takeUntilDestroyed(this)).subscribe(() => {
      this.detach();
      this.hidden.next();
    });

    this.updateTooltipInputs();

    this.tooltipInstance?.show();

    this.store.updateFields({ state: 'shown' });
  }

  hide(): void {
    if (!this.tooltipInstance) {
      return;
    }

    this.tooltipInstance.hide();
  }

  updateOverlayPosition(): void {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.updatePosition();
  }

  updateTooltipPosition() {
    // wait for first painting before position update
    this.ngZone.onMicrotaskEmpty
      .asObservable()
      .pipe(
        take(1),
        filter(() => !!this.tooltipInstance && !!this.overlayRef),
        takeUntilDestroyed(this),
      )
      .subscribe(() => this.overlayRef!.updatePosition());
  }


  private detach(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    this.tooltipInstance = null;
  }

  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const positionStrategy = this.getOverlayPositionStrategy();
    const scrollStrategy = this.getOverlayScrollStrategy();

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
    });

    this.updatePosition();

    this.handlePositionChanges(positionStrategy);

    this.overlayRef
      .detachments()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this.detach());

    return this.overlayRef;
  }

  private getOverlayPositionStrategy(): FlexibleConnectedPositionStrategy {
    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    return this.overlay
      .position()
      .flexibleConnectedTo(this.getTooltipReferenceElement())
      .withFlexibleDimensions(false)
      .withViewportMargin(POSITION_VIEWPORT_MARGIN_PX)
      .withScrollableContainers(scrollableAncestors);
  }

  private getOverlayScrollStrategy(): RepositionScrollStrategy {
    return this.overlay.scrollStrategies.reposition({
      scrollThrottle: SCROLL_THROTTLE_MS,
    });
  }

  private updatePosition(): void {
    if (!this.overlayRef) {
      return;
    }

    const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = getOriginPosition(this.position);
    const overlay = getOverlayPosition(this.position);

    positionStrategy.withPositions([
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback },
    ]);
  }

  private handlePositionChanges(positionStrategy: FlexibleConnectedPositionStrategy): void {
    const position$ = positionStrategy.positionChanges.pipe(
      map(positionChange => getPositionFromChange(this.position, positionChange)),
      share(),
    );

    position$
      .pipe(
        filter(() => !!this.tooltipInstance),
        takeUntilDestroyed(this),
      )
      .subscribe(position => this.tooltipInstance!.setPosition(position));

    position$
      .pipe(
        map(position => getPositionOffset(position, DEFAULT_TOOLTIP_OFFSET_PX)),
        takeUntilDestroyed(this),
      )
      .subscribe(({ offsetX, offsetY }) => {
        positionStrategy.withDefaultOffsetX(offsetX);
        positionStrategy.withDefaultOffsetY(offsetY);
      });
  }

  private initLifecycle(): void {
    /**
     * Для минимизации возможности гонки состояний
     * берем интервал в 16мс — чаще нет смысла пытаться изменить UI
     * Первую половину интервала накапливаем изменения стора,
     * во вторую планируем UI изменения и производим их, если ничего не изменилось.
     */
    const DELAY_MS = 8;
    const store$ = this.store.select().pipe(debounceTime(DELAY_MS));
    const trigger$ = store$.pipe(distinctUntilKeyChanged('trigger'), pluck('trigger'));
    const shownState$ = store$.pipe(
      distinctUntilKeyChanged('state'),
      filter(({ state }) => state === 'shown'),
    );
    const hiddenState$ = store$.pipe(
      distinctUntilKeyChanged('state'),
      filter(({ state }) => state === 'hidden'),
    );
    trigger$
      .pipe(
        switchMap(trigger => {
          let delay: number;
          if (trigger === 'show') {
            // don't break logic, if DEFAULT_SHOW_DELAY_MS will be changed
            delay = DELAY_MS > DEFAULT_SHOW_DELAY_MS ? DELAY_MS : DEFAULT_SHOW_DELAY_MS;
          } else {
            delay = DELAY_MS;
          }
          return timer(delay).pipe(mapTo(trigger));
        }),
        takeUntilDestroyed(this),
      )
      .subscribe(trigger => {
        if (trigger === 'show') {
          this.show();
        } else if (trigger === 'hide') {
          this.hide();
        }
      });

    shownState$
      .pipe(
        switchMap(() => this.tooltipInstance!.preventHiding$.pipe(skip(1))),
        distinctUntilChanged(),
        takeUntilDestroyed(this),
      )
      .subscribe(preventHiding => {
        this.store.updateFields({ trigger: preventHiding ? 'show' : 'hide' });
      });

    shownState$
      .pipe(
        switchMap(() => this.tooltipInstance!.hidden$.pipe(take(1))),
        takeUntilDestroyed(this),
      )
      .subscribe(() => {
        this.store.updateFields({ state: 'hidden' });
      });

    hiddenState$.pipe(takeUntilDestroyed(this)).subscribe(() => {
      this.detach();
      this.hidden.next();
    });
  }

  private onMouseEnter(): void {
    this.store.updateFields({ trigger: 'show' });
  }

  private onMouseLeave(): void {
    this.store.updateFields({ trigger: 'hide' });
  }

  private getTooltipReferenceElement(): HTMLElement {
    return this.tooltipReferenceDirective?.nativeElement ?? this.elementRef.nativeElement;
  }

  private initHoverElementEventHandlers(): void {
    fromEvent(this.elementRef.nativeElement, 'mouseenter')
      .pipe(
        filter(() => !this.disabled),
        takeUntilDestroyed(this),
      )
      .subscribe(() => this.onMouseEnter());

    fromEvent(this.elementRef.nativeElement, 'mouseleave')
      .pipe(
        filter(() => !this.disabled),
        takeUntilDestroyed(this),
      )
      .subscribe(() => this.onMouseLeave());
  }


  private updateTooltipInputs(): void {
    if (!this.tooltipInstance) {
      return;
    }

    this.tooltipInstance.setOptions({
      size: this.size,
      type: this.type,
      content: this.content,
      contentRef: this.contentRef,
      contentRefContext: this.contentRefContext,
      actionRef: this.actionRef,
      maxWidth: this.maxWidth,
      disabled: this.disabled,
      interactive: this.interactive,
      ignorePointerEvents: this.ignorePointerEvents,
      style: this.style
    });

    this.updateTooltipPosition();
  }
}
