import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import imageSrc from './images/404.png';

interface AnotherAction{
  link: string;
  description: string;
}

@Component({
  selector: 'app-error-page-layout',
  templateUrl: 'error-page-layout.html',
  styleUrls: ['error-page-layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppErrorPageLayoutComponent {
  @Input() showReloadPageButton = false;
  @Input() anotherActionButton: AnotherAction | null = null;
  @Input() primaryActionButton: AnotherAction | null = null;
  @Input() isMobile: boolean;
  @Input() image: string = imageSrc;

  reload(): void {
    window.history.go(-1);
  }

  anotherAction(): void | boolean {
    switch (this.anotherActionButton?.link) {
      case 'mash': window.location.href = 'https://uchebnik.mos.ru/'; return;
      case 'mo': window.location.href = 'https://uchebnik.mos.ru/'; return;
      default: window.history.go(-1); return false;
    }
  }

  primaryAction(): void{
    window.location.href = this.primaryActionButton?.link as string;
  }
}
