import { TooltipPosition } from '../lecta-tooltip.interface';

export function getPositionOffset(
  position: TooltipPosition,
  defaultOffsetPx: number,
): { offsetX: number; offsetY: number } {
  let offsetX = 0;
  let offsetY = 0;

  if (position === 'top') {
    offsetY = -defaultOffsetPx;
  } else if (position === 'bottom') {
    offsetY = defaultOffsetPx;
  } else if (position === 'right') {
    offsetX = defaultOffsetPx;
  } else if (position === 'left') {
    offsetX = -defaultOffsetPx;
  }

  return { offsetX, offsetY };
}
